<template>
  <div class="page">

    <div class="box1">
      <div></div>
      <img src="@/assets/gis/caso.png"
           alt="">
    </div>

    <div class="box2">

    </div>
    <div class="box3">
      <div class="from">
        <div class="title">
          <p>账号注册</p>
          <p>已有账号?<span @click="login">点击登录</span></p>
        </div>
        <div class="list">
          <el-form ref="form"
                   :model="form"
                   :rules="rules"
                   label-width="100px"
                   :class="{ tight: selectedType !== 1 }">
            <el-form-item label="用户名"
                          class="iop"
                          prop="userName">
              <el-input v-model="form.userName"></el-input>
            </el-form-item>
            <el-form-item label="邮箱"
                          class="iop"
                          prop="email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="密码"
                          class="iop"
                          prop="password">

              <el-input v-model="form.password"
                        type="password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码"
                          class="iop"
                          prop="checkPass">
              <el-input v-model="form.checkPass"
                        type="password"></el-input>
            </el-form-item>
            <!-- <el-form-item label="所在地区"
                          class="iop"
                          prop="area">
              <area-select :level="2"
                           type="text"
                           v-model="form.area"
                           :data="pcaa"></area-select>
            </el-form-item> -->
            <!-- <el-form-item label="手机号"
                          prop="checkPass">
              <el-input v-model="form.phone"></el-input>
            </el-form-item> -->
            <template v-if="selectedType === 2">

              <el-form-item label="学校名称"
                            class="iop"
                            prop="school">
                <el-input v-model="form.school"></el-input>
              </el-form-item>

            </template>
            <template v-if="selectedType === 3">
              <!-- <el-form-item label="所在机构"
                            class="iop"
                            prop="orgType">
                <el-select v-model="form.orgType"
                           placeholder="请选择">
                  <el-option v-for="(item, i) in orgTypeList"
                             :key="i"
                             :label="item"
                             :value="item">
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="机构名称"
                            class="iop"
                            prop="org">
                <el-input v-model="form.org"></el-input>
              </el-form-item>
              <!-- <el-form-item label="联系方式"
                            class="iop"
                            prop="phone">
                <el-input v-model="form.phone"></el-input>
              </el-form-item> -->
            </template>

            <!-- <el-form-item v-if="selectedType !== 0"
                          label="手机号">
              <el-input v-model="form.phone"></el-input>
            </el-form-item> -->
            <template v-else>

            </template>
            <el-form-item label="验证码"
                          prop="verifyCode"
                          class="obx">
              <el-input class="input-code"
                        v-model="form.verifyCode"></el-input><el-button :disabled="count !== 0"
                         class="btn-code"
                         @click="getVerifyCode">{{ count === 0 ? "获取验证码" : `重新发送(${count}s)` }}</el-button>
            </el-form-item>
          </el-form>
          <span style="position: relative;top: -10px;left: 50px;font-size: 12px;color: red;">请注意邮箱接收的验证码</span>

        </div>
        <div class="btn"
             @click="registerHandler">注 册</div>
      </div>

    </div>
    <div class="panel">
      <!-- <el-form-item label="出生日期"
                              prop="birthday">
                  <el-date-picker v-model="form.birthday"
                                  value-format="yyyy-MM-dd"
                                  type="date"
                                  placeholder="选择日期">
                  </el-date-picker>
                </el-form-item> -->
      <!-- <el-form-item class="grade"
                              label="年级和班级"
                              prop="grade">
                  <el-input v-model="form.grade"><template slot="append">年级</template></el-input>
                  <el-input v-model="form.className"><template slot="append">班级</template></el-input>
                </el-form-item> -->

      <!-- <el-form-item label="备注">
                  <el-input v-model="form.remark"
                            placeholder="可以描述加入本人经历，或加入本网站的目的"></el-input>
                </el-form-item> -->
    </div>
  </div>
</template>
<script>
import { pcaa } from "area-data";
import { $user } from "@/services";
import { user_type_list } from "@/data/usertype";
const COUNTDOWN = 120;
export default {
  name: "Signup",
  data () {
    let validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入邮箱"));
      } else if (
        /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(
          value
        )
      ) {
        callback();
      } else {
        callback(new Error("请输入有效的邮箱地址"));
      }
    };
    let validateUserName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        $user.hasUser(value).then((res) => {
          if (res.data == 0) {
            callback();
          } else {
            callback(new Error("用户名已被注册，请换一个！"));
          }
        });
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.checkPass !== "") {
          this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      timer: null,
      count: 0,
      pcaa,
      selectedType: 1,
      typeList: user_type_list,
      schoolTypeList: [
        {
          val: 1,
          name: "小学",
        },
        {
          val: 2,
          name: "初中",
        },
        {
          val: 3,
          name: "高中",
        },
        {
          val: 4,
          name: "其他",
        },
      ],
      orgTypeList: [
        "幼儿园",
        "小学",
        "初中",
        "高中（中职）",
        "高校",
        "科技馆（活动中心）",
        "气象局",
        "生态环境局",
        "水利局",
        "其他机构",
      ],
      rules: {
        userName: [
          { required: true, validator: validateUserName, trigger: "blur" },
        ],
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        area: [{ required: true, message: "请选择地区", trigger: "blur" }],
        birthday: [{ required: true, message: "请选择生日", trigger: "blur" }],
        orgType: [
          { required: true, message: "请选择所在机构", trigger: "blur" },
        ],
        org: [{ required: true, message: "请输入机构名称", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
        grade: [
          { required: true, message: "请输入年级和班级信息", trigger: "blur" },
        ],
        school: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
        ],
        schoolType: [
          { required: true, message: "请选择学校类型", trigger: "blur" },
        ],
        verifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      form: {
        username: "",
        email: "",
        password: "",
        checkPass: "",
        area: [],
        code: "",
        recommend: "",
        phone: "",
        birthday: "",
        school: "",
        org: "",
        orgType: "",
      },
    };
  },
  computed: {
    sheng () {
      return this.form.area && this.form.area[0];
    },
    shi () {
      return this.form.area && this.form.area[1];
    },
    xian () {
      return this.form.area && this.form.area[2];
    },
  },
  created () {
    this.selectedType = +this.$route.query.type;
    this.visibilityChangeHandler();
  },
  destroyed () {
    this.timer && clearInterval(this.timer);
  },
  methods: {
    login () {
      this.$router.push("login");
    },
    typeClickHandler (val) {
      this.selectedType = val;
      this.$refs.form.clearValidate();
    },
    getVerifyCode () {
      this.$refs.form.validateField("email", (err) => {
        if (!err) {
          $user.getVerifyCode(this.form.email).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "验证码已发送到您的邮箱，请注意查收",
                type: "success",
              });
              this.setTimer();
            }
          });
        }
      });
    },
    visibilityChangeHandler () {
      let start, end, diff;
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
          start = new Date().getTime();
        } else if (document.visibilityState === "visible") {
          end = new Date().getTime();
          diff = Math.floor((parseInt(end) - parseInt(start)) / 1000);
          if (this.count > 0) {
            this.count = Math.max(0, this.count - diff);
          }
        }
      });
    },
    setTimer () {
      this.count = COUNTDOWN;
      this.timer = setInterval(() => {
        if (this.count > 0) this.count--;
      }, 1000);
      setTimeout(() => {
        clearInterval(this.timer);
        this.timer = null;
      }, COUNTDOWN * 1000);
    },
    registerHandler () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          $user
            .register({
              ...this.form,
              type: this.selectedType,
              sheng: this.sheng,
              shi: this.shi,
              xian: this.xian,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "注册成功",
                  type: "success",
                });
                this.$router.push("login");
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  .box1 {
    height: 30vh;
    position: relative;
    div {
      width: 100%;
      height: 100%;
      filter: blur(10px);
      background-color: #158bb8;
      img {
        position: absolute;
        width: 30%;
        bottom: 0;
        left: calc(50% - 15%);
      }
    }
  }
  .box2 {
    height: 70vh;
    background-color: #cdd1d3;
    position: relative;

    .input-code {
      width: auto;
    }
  }
  .box3 {
    background-image: url('../assets/back.png');
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666;
    .from {
      padding: 30px;
      background-color: aliceblue;
      box-shadow: 0px 2px 6px 0px rgba(181, 181, 181, 0.9);
      border-radius: 3px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      /* justify-content: space-around; */
      align-items: center;
      justify-content: center;

      > :nth-child(2) {
        position: relative;
        left: -10px;
        width: 350px;
        > :nth-child(1) {
          > :nth-child(n) {
            > :nth-child(1) {
              font-size: 18px;
            }
          }
          > :nth-last-child(1) {
            > :nth-child(2) {
              display: flex;
              > :nth-child(1) {
                flex: 1;
              }
              > :nth-child(2) {
                width: 80px;
                margin: 0;
                > :nth-child(1) {
                  position: relative;
                  left: -15px;
                }
              }
            }
          }
        }
      }
      .title {
        margin-bottom: 10px;
        p {
          span {
            color: #158bb8;
            cursor: pointer;
          }
        }
      }
      .title > p:nth-child(1) {
        text-align: center;
        font-size: 34px;
        margin-bottom: 10px;
      }
      .btn {
        width: 100px;
        font-size: 24px;
        box-shadow: 0px 2px 6px 0px rgba(181, 181, 181, 0.9);
        border: 1px solid #bcbcbc;
        text-align: center;
        margin: 15px auto 0;
        cursor: pointer;
        padding: 3px 0;
        border-radius: 3px;
      }
      .btn:hover {
        background-color: #158bb8;
        color: #fff;
      }
      // .list {
      //   display: flex;
      //   flex-direction: column;
      //   ul {
      //     li {
      //       display: flex;
      //       justify-content: space-between;
      //       height: 40px;
      //       margin-bottom: 10px;
      //       div {
      //         flex: 1;
      //         display: flex;
      //         margin-left: 10px;
      //         border: 1px solid #bcbcbc;
      //         input {
      //           border: none;
      //           background-color: transparent;
      //           outline: 0;
      //           height: 100%;
      //           padding: 0 10px;
      //         }
      //         .yesno {
      //           background-color: #158bb8;
      //           width: 120px;
      //           font-size: 12px;
      //           text-align: center;
      //           line-height: 40px;
      //           color: #fff;
      //           cursor: pointer;
      //         }
      //       }
      //       p {
      //         display: flex;
      //         font-size: 24px;
      //         width: 110px;
      //         justify-content: flex-end;
      //         span {
      //           color: red;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  // width: 100%;
  // height: 100vh;
  // background: url('../assets/image/bg-signup.png') left center no-repeat,
  //   url('../assets/image/bg-earth.png') top 66px left 700px no-repeat;
  // background-size: 25% 102%, 290px auto;
  // background-color: #f4f4f4;
  // display: flex;
  // .panel {
  //   width: 646px;
  //   padding: 0 30px;
  //   margin: 50px 300px 50px auto;
  //   background: #fff;
  //   border-radius: 20px;
  //   position: relative;
  //   h3 {
  //     margin: 32px 0 50px;
  //     font-size: 30px;
  //     text-align: center;
  //   }
  //   .login {
  //     position: absolute;
  //     right: 22px;
  //     top: 43px;
  //     font-size: 18px;
  //     em {
  //       cursor: pointer;
  //       color: #0160fe;
  //     }
  //   }
  //   .tab {
  //     margin-bottom: 29px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //     .item {
  //       transition: all 0.3s;
  //       cursor: pointer;
  //       margin: 0 0.5em;
  //       width: 104px;
  //       line-height: 30px;
  //       border: 1px solid #dadada;
  //       border-radius: 15px;
  //       font-size: 18px;
  //       text-align: center;
  //       &.active {
  //         background: #00baff;
  //         color: #fff;
  //       }
  //     }
  //   }
  //   .el-form {
  //     .grade {
  //       .el-input {
  //         width: 45%;
  //         &:first-of-type {
  //           margin-right: 10%;
  //         }
  //       }
  //     }
  //     .input-code {
  //       width: calc(100% - 13em);
  //     }
  //     .btn-code {
  //       margin-left: 2em;
  //       width: 10em;
  //     }
  //     .btn-submit {
  //       width: 415px;
  //       height: 60px;
  //       border-radius: 30px;
  //       background: #00baff;
  //       color: #fff;
  //       font-size: 30px;
  //     }
  //   }
  //   .tight {
  //     .el-form-item {
  //       margin-bottom: 16px;
  //     }
  //   }
  // }

  /deep/ .area-select {
    line-height: 1;
    margin: 0;
    &.medium {
      width: 153.5px;
    }
  }
  .obx {
  }
  .input-code {
    width: auto;
  }
  .btn-code {
    margin-left: 20px;
  }
  .ipo {
  }
}
</style>