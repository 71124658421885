export const user_type_list = [
  {
    val: 1,
    name: "爱好者",
  },
  {
    val: 2,
    name: "学生用户",
  },
  {
    val: 3,
    name: "校园用户",
  },
];
